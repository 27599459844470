import React from 'react';

export interface UpgradePrice {
  priceWithTaxTotal: number;
  priceUnit: string;
  status: 'PENDING' | 'COMPLETE' | 'ERROR' | 'NA';
}

interface IAppContext {
  upgradePrice: UpgradePrice;
  setUpgradePrice: { (price: UpgradePrice): void };
}

const initial: IAppContext = {
  upgradePrice: {
    priceWithTaxTotal: NaN,
    priceUnit: '',
    status: 'NA',
  },
  setUpgradePrice: () => {},
}

export const AppContext = React.createContext<IAppContext>(initial);

const AppContextProvider = ({children, initialValue = initial}: {children: React.ReactChild, initialValue?: IAppContext}) => {
  const [state, setState] = React.useState<IAppContext>(initialValue);

  const setUpgradePrice = (price: UpgradePrice) => setState({ ...state, upgradePrice: price })

  return (
    <AppContext.Provider value={{ ...state, setUpgradePrice }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
