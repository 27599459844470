import '../styles/call-support.scss';
import React from 'react';

interface IProps {
  title: string;
  button: string;
}

const CallSupport = (props: IProps) => {
  return (
    <>
      <h2 className="call-support-title">{props.title}</h2>
      <div className="call-support-wrapper">
        <a className="button purple white-border" id="telephone-link" href="tel:0771226644">{props.button}</a>
      </div>
    </>
  );
};

export default CallSupport;
