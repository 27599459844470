import '../styles/selling-points.scss';
import React from 'react';

interface IProps {
  uspList: {
    heading: string;
    text: string;
    icon: string;
  }[];
}

function SellingPoints(props: IProps) {
  return (
    <div className="selling-points-wrapper">
      <div className="selling-points">
        {props.uspList.map((usp, index: any) => {
          return (
            <div className="selling-point" key={index}>
              <div className="icon-background">
                <img className="icon" src={usp.icon} alt="Usp icon" />
              </div>
              <div className="text-wrapper">
                <h3 className="title">{usp.heading}</h3>
                <p className="description">{usp.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SellingPoints;
