import briefcase from '../assets/icons/briefcase.svg';
import download from '../assets/icons/download.svg';
import invoice from '../assets/icons/invoice.svg';
import play from '../assets/icons/play.svg';
import wifi from '../assets/icons/wifi.svg';

export const premiumFeatures = [
  {
    text: 'Wifi genom hela Europa',
    sub: '20 GB/mån'
  },
  {
    text: 'Körjournal'
  },
  {
    text: 'Samtliga Basfunktioner'
  }
];
export const basicFeatures = [
  {
    text: 'Körscore'
  },
  {
    text: 'Hitta min bil'
  },
  {
    text: 'Larm och påminnelser'
  },
  {
    text: 'Smarta tilläggstjänster'
  }
];
export const upgradeViewContent = {
  wifi: {
    heading: 'Gör din bil ännu smartare',
    description: 'Lås upp all information, få tillgång till våra smarta partnertjänster och få superkoll på din bilhälsa. Säg upp Premium när du vill.',
    buttonText: (upgradePrice: any) => `Uppgradera för ${upgradePrice}`,
    sellingPoints: [
      {
        icon: wifi,
        heading: 'Wifi i Sveriges bästa nät',
        text: '4G wifi i bilen med Sveriges bästa uppkoppling ger dig pålitlig surf var du än är.'
      },
      {
        icon: invoice,
        heading: 'Full koll på dina resor',
        text: 'Automatisk sammanställning av din resedata så du får koll på var och när bilen används.'
      },
      {
        icon: play,
        heading: '20 GB genom hela Europa',
        text: 'Streama film, spel eller hela familjens underhållning under bilresan. Alla blir nöjda.'
      }
    ]
  },
  journal: {
    heading: 'Exportera dina resor',
    description: 'Lås upp all information, få tillgång till våra smarta partnertjänster och få superkoll på din bilhälsa. Säg upp Premium när du vill.',
    buttonText: (upgradePrice: any) => `Uppgradera för ${upgradePrice}`,
    sellingPoints: [
      {
        icon: briefcase,
        heading: 'Arbetsresor',
        text: 'Kör du i tjänsten? Sense loggar automatiskt start och stopp av resor och biltullar.'
      },
      {
        icon: download,
        heading: 'Automatisk export',
        text: 'Med automatisk export av din körjournal missar du aldrig en reserapport.'
      },
      {
        icon: invoice,
        heading: 'Körjournal',
        text: 'Sammanställ enkelt och smidigt dina resor för att få rätt ersättning för dina utlägg.'
      }
    ]
  },
  account: {
    heading: 'Uppgradera till Premium,<br />gör din bil ännu smartare',
    description: 'Lås upp all information, få tillgång till våra smarta partnertjänster och få superkoll på din bilhälsa. Säg upp Premium när du vill.',
    buttonText: (upgradePrice: any) => `Uppgradera för ${upgradePrice}`,
    sellingPoints: [
      {
        icon: wifi,
        heading: 'Wifi i Sveriges bästa nät',
        text: '4G wifi i bilen med Sveriges bästa uppkoppling ger dig pålitlig surf var du än är.'
      },
      {
        icon: invoice,
        heading: 'Full koll på dina resor',
        text: 'Automatisk sammanställning av din resedata så du får koll på var och när bilen används.'
      },
      {
        icon: play,
        heading: '20 GB genom hela Europa',
        text: 'Streama film, spel eller hela familjens underhållning under bilresan. Alla blir nöjda.'
      }
    ]
  },
  general: {
    heading: 'Gör din bil ännu smartare',
    description: 'Lås upp all information, få tillgång till våra smarta partnertjänster och få superkoll på din bilhälsa. Säg upp Premium när du vill.',
    buttonText: (upgradePrice: any) => `Uppgradera för ${upgradePrice}`,
    sellingPoints: [
      {
        icon: wifi,
        heading: 'Wifi i Sveriges bästa nät',
        text: '4G wifi i bilen med Sveriges bästa uppkoppling ger dig pålitlig surf var du än är.'
      },
      {
        icon: invoice,
        heading: 'Körjournal & reseräkning',
        text: 'Låt Sense löpande sköta både din körjournal och rapporteringen till din ekonomipartner.'
      },
      {
        icon: play,
        heading: '20 GB genom hela Europa',
        text: 'Streama film, spel eller hela familjens underhållning under bilresan. Alla blir nöjda.'
      }
    ]
  }
};

export const faqList = (upgradePrice: string) => [
  {
    question: 'Vilka funktioner ingår i Premium?',
    answer: 'I Sense Premium får du, utöver alla de funktioner som ingår i Sense Bas, även tillgång till:',
    list: [
      'Wifi i din bil inklusive 20 GB surf per månad som fungerar i hela EU/EES',
      'Utökad körjournalsdata: tids-och datumstämplar för resor, resekategorisering, reslängder (tim:min), tidpunkt och kostnad för vägtullar',
      'Möjligheten att exportera din körjournal i form av en excelfil och skicka till utvald epostadress',
      'Möjligheten att automatisk sammanställa din körjournal och skicka iväg till ditt ekonomisystem varje månad'
    ],
    isListNumbered: false
  },
  {
    question: 'Vilka funktioner ingår i Bas?',
    answer: 'I Telia Sense Bas ingår alla funktioner som finns i Telia Sense appen ex. Åverkanslarm, Varningar &  Notiser, Hitta bilen, Parkeringshjälp, Dela Resa, Partnertjänster, Statistik osv. Däremot ingår inte 20 GB wifi, körjournal och möjlighet till automatisk export till ekonomisystemet.'
  },
  {
    question: 'Vilka funktioner saknas i Bas?',
    answer: 'I Telia Sense Bas saknar du tillgång till alla de funktioner som ingår i Telia Sense Premium d.v.s.:',
    list: [
      'Wifi i din bil inklusive 20 GB surf per månad som fungerar i hela EU/EES',
      'Utökad körjournalsdata: tids-och datumstämplar för resor, resekategorisering, reslängder (tim:min), tidpunkt och kostnad för vägtullar',
      'Möjligheten att exportera din körjournal i form av en excelfil och skicka till utvald epostadress',
      'Möjligheten att automatisk sammanställa din körjournal och skicka iväg till ditt ekonomisystem varje månad'
    ],
    isListNumbered: false
  },
  {
    question: 'Vad kostar Premium?',
    answer: `Telia Sense Premium kostar ${upgradePrice} inkl. moms. Utöver tjänster och funktioner i Telia Sense Bas ingår då 4G WiFi med 20 GB surf per månad samt full körjournalsfunktionalitet.`,
  },
  {
    question: 'Hur betalar man för Telia Sense Premium?',
    answer: 'Du betalar för Telia Sense Premium genom att lägga till tjänsten via Telia Sense appen och betalar då månadsvis med kreditkort.'
  },
  {
    question: 'Vad kostar Bas?',
    answer: 'Telia Sense Bas är en kostnadsfri tjänst. Du betalar ingenting för användningen av Telia Sense Bas.'
  },
  {
    question: 'Hamnar det här på min Paydrive-faktura?',
    answer: 'Telia Sense Premium är en fristående tjänst som levereras och faktureras av Telia. Månadsavgiften för Telia Sense Premium betalar du till Telia, separat från eventuella partnertjänster du köper som baserar sig på Telia Sense.'
  },
  {
    question: 'Varför måste jag registrera mitt kreditkort när jag köper Telia Sense Premium?',
    answer: 'Du behöver registrera ditt kreditkort i Telia Sense appen för att kunna betala för Telia Sense Premium. Du kommer fortsättningsvis, precis som innan, betala din försäkring till Paydrive på samma sätt som du gör idag.'
  },
  {
    question: 'Hur hänger Paydrive och Premium ihop?',
    answer: 'När du tecknar en digital bilförsäkring hos Paydrive ingår Telia Sense Premium utan kostnader i 6 månader som en välkomstgåva (värde 594 kr). Premiumtjänsten förnyas inte automatiskt. Efter 6 månader övergår du till Telia Sense Bas som alltid ingår i din Paydrive-försäkring. Du kan också välja att förlänga Telia Sense Premium för att fortsätta ta del av alla fördelar.'
  },
  {
    question: 'Hur lång är bindningstiden för Premium?',
    answer: 'Det finns ingen bindningstid för Premium. Du betalar månadsvis. Väljer du att avsluta Premium har du tillgång till Premiumfunktioner till och med slutet av din betalda period.'
  },
  {
    question: 'Kan jag fortfarande använda bil-wifit om jag inte uppgraderar till Premium?',
    answer: 'Nej, du kommer inte kunna använda ditt bil-wifi om du inte uppgraderar till Telia Sense Premium.'
  },
  {
    question: 'Vad ingår i bil-wifi-funktionen?',
    answer: 'I bil-wifi-funktionen ingår 20 GB/mån wifi i bilen. Du får 4G-wifi i bilen i hela Sverige och EU/EES. 5 enheter kan vara anslutna till wifi-funktionen samtidigt och ditt wifi är tillgängligt så länge bilen är igång.'
  },
  {
    question: 'Vilken körjournalsdata/funktionalitet har jag tillgång till i Telia Sense Bas?',
    answer: 'I Bas kan du fortfarande se kartbilden över hur du har kört, från vilken adress och till vilken adress, samt att du ser hur lång (km) körsträckan var. Du kan även se vägtullar.'
  },
  {
    question: 'Kan jag fortsätta använda "Expense Reporting" för automatisk reseräkning för mina tjänsteresor om jag har Telia Sense Bas?',
    answer: 'Nej, Expense Reporting kräver att du har tillgång till den fulla Körjournalen (Premium) för att kunna exportera den till din Expense Reporting partner.'
  },
  {
    question: 'Vilken statistik och resedata förlorar jag tillgång till om jag har Telia Sense Bas?',
    answer: 'Du kan inte se tidpunkterna för resan. Du kan inte heller exportera din körjournal till Excel-fil och saknar möjligheten att automatiskt skicka din körjournal till ditt ekonomisystem.'
  },
  {
    question: 'Kan jag fortsätta använda Partnertjänsterna om jag har Telia Sense Bas?',
    answer: 'Ja, alla partnertjänster, förutom Expense Reporting, fungerar precis som vanligt.'
  },
  {
    question: 'Kan jag uppgradera till Premium vid senare tillfälle?',
    answer: 'Ja, du kan närsomhelst uppgradera till Telia Sense Premium och få tillgång till ditt wifi i bilen samt din fulla körjournal.'
  },
  {
    question: 'Vad händer om man inte uppgraderar till Telia Sense Premium?',
    answer: 'Om du väljer att inte uppgradera till Premium så kommer du, när din gratisperiod löper ut, förlora tillgång till ditt 20 GB wifi i bilen samt din körjournal och möjligheten att automatiskt skicka iväg din körjournalrapport till ditt ekonomisystem varje månad.'
  },
  {
    question: 'Om jag väljer att uppgradera till Premium vid ett senare tillfälle - kommer jag kunna se körjournalsdatan retroaktivt?',
    answer: 'Ja, din kördata lagras och låses upp så snart du uppgraderar till Telia Sense Premium.'
  },
  {
    question: 'Kommer min Paydrive försäkring fungera precis som vanligt om jag inte uppgraderar?',
    answer: 'Ja, Paydrive kommer att fortsätta fungera precis som vanligt även om du väljer att inte uppgradera till Telia Sense Premium.'
  },
  {
    question: 'Om jag inte köper Premium nu - försvinner min körjournalsdata?',
    answer: 'Din körjournalsdata fortsätter att lagras löpande även om du inte har Telia Sense Premium.'
  }
];
