import '../styles/feature-list.scss';
import React from 'react';
import CheckmarkIcon from '../assets/icons/checkmark.svg';

interface IProps {
  features: {
    text: string;
    sub?: string;
  }[];
  title?: string;
}

const FeatureList = (props: IProps) => (
  <div className="feature-list-wrapper">
    {props.title && <h4 className="title">{props.title}</h4>}
    <div className="feature-list">
      {props.features.map((feature, index) => {
        return (
          <div className="feature" key={index}>
            <div className="icon-wrapper">
              <img className="icon" src={CheckmarkIcon} alt="Feature icon" />
            </div>
            <div className="text-wrapper">
              <p className="text">{feature.text}</p>
              {
                feature.sub !== undefined &&
                <p className="sub-text">{feature.sub}</p>
              }
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default FeatureList;
