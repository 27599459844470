import '../styles/price-info.scss';
import React from 'react';

import LoadingSpinner from '../assets/icons/loading-bars.gif';

interface IProps {
  price?: number;
  unitString?: string;
}

const PriceInfo = (props: IProps) => (
  <div className="price-info">
    <div className={`title ${(props.price && props.unitString) ? 'black' : 'gray'}`}><h3>Totalt</h3></div>
    {
      props.price && props.unitString ?
        <div className="amount">
          <h3>{props.price + ' ' + props.unitString} <span>(ingen bindningstid)</span></h3>
        </div> :
        <div className="icon"><img src={LoadingSpinner} alt="Loading price" /></div>
    }
  </div>
);

export default PriceInfo;
