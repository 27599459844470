import '../styles/adyen-credit-card-form.scss';
import React, { useEffect } from 'react';
import { ADYEN_ENV, ADYEN_ORIGIN_KEY } from '../config';

declare var AdyenCheckout: any;

export function AdyenCreditCardForm(props: { onChange: Function, title?: string }) {
  useEffect(() => {
    const adyenConfig = {
      locale: 'sv-SE',
      environment: ADYEN_ENV,
      originKey: ADYEN_ORIGIN_KEY,
      paymentMethodsResponse: {}
    };

    (new AdyenCheckout(adyenConfig)).create('card', {
      placeholders: {
        encryptedCardNumber: 'XXXX XXXX XXXX XXXX',
        encryptedExpiryDate: 'MM/ÅÅ',
        encryptedSecurityCode: 'XXX'
      },
      showPayButton: false,
      onChange: props.onChange
    }).mount('#adyen-drop-in');
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <div className="adyen-credit-card-box">
      {props.title && <h3>{props.title}</h3>}
      <div id="adyen-drop-in"></div>
    </div>
  );
}
