import '../styles/faq.scss';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';

interface IProps {
    questions: {
        question: string;
        answer?: string;
        list?: {

        }[];
        isListNumbered?: boolean;
    }[];
    title: string;
}

const FAQ = (props: IProps) => {
    const [showQuestions, setShowQuestions] = useState(false);

    return (
        <div className='question-wrapper'>
            <h2 className='faq-title'>{props.title}</h2>
            <div className='question-list'>
                {props.questions.map((question, index) => {
                    if (showQuestions || index < 3) {
                        return (
                            <Collapsible className='question' transitionTime={50} trigger={question.question} key={index}>
                                {question.answer !== undefined && <p className='sub-text'>{question.answer}</p>}
                                {question.list !== undefined && (
                                    question.isListNumbered === true ? (
                                        <ol data-testid='numbered-list'>
                                            {question.list!.map((listItem, index) => {
                                                return (<li className="sub-text" key={index}>{listItem}</li>);
                                            })}
                                        </ol>
                                    ) : (
                                            <ul data-testid="dotted-list">
                                                {question.list!.map((listItem, index) => {
                                                    return (<li className="sub-text" key={index}>{listItem}</li>);
                                                })}
                                            </ul>
                                        )
                                )}
                            </Collapsible>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
            <div className='show-questions-wrapper'>
                <p className="show-questions-text" onClick={() => { setShowQuestions(!showQuestions); }} >
                    {showQuestions ? 'Dölj frågor' : 'Visa alla frågor'}
                </p>
            </div>
        </div>
    );
};

export default FAQ;
