import '../styles/confirmation.scss';
import React from 'react';
import pebble from '../assets/icons/pebble.svg';
import { Link } from 'react-router-dom';

interface IProps {
  successful: boolean;
  token: string;
  userInfo: any;
  accountType: string;
}

export default function Confirmation(props: IProps) {

  const successHeader = (props.accountType === 'premium') ? <h1>Din information <span>har uppdaterats</span></h1> : <h1>Du har nu uppgraderat <span>till Premium</span></h1>;
  return (
    <div className="confirmation-wrapper">
      {
        (props.successful) ?
          <>
            <img src={pebble} alt="Telia logo" />
            {successHeader}
            <p>Grattis! Du har nu säkrat ett enklare billiv med Telia Sense Premium.</p>
            <br />
            <p>Du kan nu återgå till appen.</p>
          </> :
          <>
            <img src={pebble} alt="Telia logo" />
            <h1>Beställningen <span>kunde inte genomföras</span></h1>
            <p>Kontrollera att du har angett korrekt information och försök igen.</p>
            <Link className={'button purple'} to={
              {
                pathname: '/order',
                search: `?token=${props.token}`,
                state: {
                  userInfo: props.userInfo
                }
              }}> Tillbaka till beställning</Link>
          </>
      }
    </div>
  );
}
