import React, { useContext, useEffect } from 'react';
import Routes from './routes';
import { BACKEND_API_ROOT, BACKEND_API_VERSION } from './config';
import { AppContext } from './context/app-context';
import './styles/app.scss';

const App: React.FC = () => {
  const appContext = useContext(AppContext);
  const controller = new AbortController();
  const signal = controller.signal;
  const isMounted = React.useRef(true);
  const token = new URLSearchParams(window.location.search).get('token') || '';

  useEffect(() => {
    if (token && !appContext.upgradePrice.priceWithTaxTotal && appContext.upgradePrice.status !== 'PENDING') {
      appContext.setUpgradePrice({...appContext.upgradePrice, status: 'PENDING'});

      fetch(
        `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/bundles/mine/price`,
        {
          headers: {
            token
          },
          signal
        }
      ).then(res => {
        if (!isMounted.current) {
          return;
        }

        if (!res.ok) {
          return appContext.setUpgradePrice({
            ...appContext.upgradePrice,
            status: 'ERROR',
          })
        }

        res.json().then(({ priceWithTaxTotal, priceUnit }: { priceWithTaxTotal: number, priceUnit: string }) => {
          appContext.setUpgradePrice({
            ...appContext.upgradePrice,
            priceUnit,
            priceWithTaxTotal,
            status: 'COMPLETE',
          })
        })
      })
    }
  }, [token, signal])

  useEffect(() => {
    return () => {
      isMounted.current = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
