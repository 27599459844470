import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UpgradeView from './containers/upgrade-view';
import OrderView from './containers/order-view';
import ProcessingView from './containers/processing-view';

export default () => (
  <Switch>
    <Route path="/(upgrade|account)?" exact={true} component={UpgradeView} />
    <Route path="/order" exact={true} component={OrderView} />
    <Route path="/processing" exact={true} component={ProcessingView} />
  </Switch>
);
