import '../styles/user-info-form.scss';
import React, { useEffect, useState, useRef, ChangeEvent } from 'react';

interface Data {
  firstName?: string;
  lastName?: string;
  email?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  telephone?: string;
}

interface Props {
  data?: Data;
  onChange: (
    changeEvent: {
      data: Data,
      isValid: boolean
    }
  ) => void;
  enabled: boolean;
  title?: string;
}

export function UserInfoForm(props: Props) {
  const { data, enabled, onChange } = props;
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    street: '',
    postalCode: '',
    city: '',
    country: 'SE'
  });

  const form = useRef(null as unknown as HTMLFormElement);

  useEffect(() => {
    const formValidity: { [key: string]: boolean } = {
      firstName: false,
      lastName: false,
      email: false,
      telephone: false,
      street: false,
      postalCode: false,
      city: false,
      country: true
    };

    const formInputs = form.current.getElementsByTagName('input');

    for (let i = 0, tot = formInputs.length; i < tot; i++) {
      const node = formInputs[i];
      const nodeIsEmpty = (!node.value) ? true : !(node.value as string).trim();
      const nodeIsValid = node.checkValidity() && !(node.required && nodeIsEmpty);
      formValidity[node.name] = nodeIsValid;
    }

    if (Object.values(formValidity).every((fieldIsValid) => fieldIsValid === true)) {
      onChange({
        data: formData,
        isValid: true
      });
    } else {
      onChange({
        data: {},
        isValid: false
      });
    }
  }, [formData, onChange]);

  useEffect(() => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      ...data,
      country: 'SE'
    }));
  }, [data]);

  function handleFormChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { target: { name, value } } = event;
    setFormData((previousFormData) => {
      const newState = {
        ...previousFormData,
        [name]: value
      };
      return newState;
    });
  }

  return (
    <div className="user-form-wrapper">
      {
        props.title && <h3>{props.title}</h3>
      }
      <form ref={form} data-testid={enabled ? 'enabled-user-info-form' : 'disabled-user-info-form'}>
        <label htmlFor="first-name" className="user-form-label">Förnamn</label>
        <input required key="firstName" id="first-name" name="firstName" placeholder="Förnamn" readOnly={!enabled} type="text" value={formData.firstName} onChange={(handleFormChange)} />

        <label htmlFor="last-name" className="user-form-label">Efternamn</label>
        <input required key="lastName" id="last-name" name="lastName" placeholder="Efternamn" readOnly={!enabled} type="text" value={formData.lastName} onChange={handleFormChange} />

        <label htmlFor="email" className="user-form-label">Email</label>
        <input required key="email" id="email" name="email" placeholder="Email" readOnly={!enabled} type="email" value={formData.email} onChange={handleFormChange} />

        <label htmlFor="telephone" className="user-form-label">Telefonnummer</label>
        <input required key="telephone" id="telephone" name="telephone" placeholder="Telefonnummer" readOnly={!enabled} type="tel" value={formData.telephone} onChange={handleFormChange} />

        <label htmlFor="street" className="user-form-label">Adress</label>
        <input required key="street" id="street" name="street" placeholder="Adress" readOnly={!enabled} type="text" value={formData.street} onChange={handleFormChange} />

        <label htmlFor="city" className="user-form-label">Ort</label>
        <input required key="city" id="city" name="city" placeholder="Ort" readOnly={!enabled} type="text" value={formData.city} onChange={handleFormChange} />

        <label htmlFor="postal-code" className="user-form-label">Postnummer</label>
        <input required key="postalCode" id="postal-code" name="postalCode" placeholder="Postnummer" readOnly={!enabled} type="text" value={formData.postalCode} onChange={handleFormChange} />

        <label htmlFor="country" className="user-form-label">Land</label>
        <div className="select">
          <select value={formData.country || 'SE'} disabled={!enabled} id="country" name="country" onChange={handleFormChange}>
            <option value="SE">Sverige</option>
          </select>
        </div>
      </form>
    </div>
  );
}
